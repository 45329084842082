import { Box, Theme } from '@mui/material'
import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import Copy from '@components/core/text/Copy'
import Button from '@components/core/input/Button'
import FryboxEmptySvg from '@static/images/icons/frybox-empty.svg'
import BucketSvg from '@static/images/icons/bucket.svg'
import FrownyFaceSvg from '@static/images/icons/frowny-face.svg'

export interface ErrorPageProps {
  pageContext: MCDC.Contentful.IPageContext
}

export default function PageError({
  pageContext,
}: ErrorPageProps): ReactElement {
  const { httpErrorCode = '404' } = pageContext

  const selectSvgByErrorCode = (httpCode: string) => {
    const httpErrorClass = httpCode.charAt(0)
    switch (httpErrorClass) {
      case '5': {
        return FrownyFaceSvg
      }
      case '4': {
        return FryboxEmptySvg
      }
      default: {
        return BucketSvg
      }
    }
  }

  const httpClassSvg = selectSvgByErrorCode(httpErrorCode)
  const httpErrorTextCode =
    httpErrorCode.charAt(0) === '4' || httpErrorCode.charAt(0) === '5'
      ? httpErrorCode.charAt(0)
      : 'X'

  return (
    <Section>
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 64px)',
          [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Box
          sx={{
            width: '192px',
          }}
          component={httpClassSvg}
        ></Box>
        <Box
          sx={(theme: Theme) => ({
            mb: 1,
            mt: 7,
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
              textAlign: 'left',
              mt: 0,
              ml: 24,
            },
          })}
        >
          <Headline variant="h1">{httpErrorCode}</Headline>
          <Copy sx={{ marginBottom: 6 }}>
            <FormattedMessage id={`error.${httpErrorTextCode}XX.copy`} />
          </Copy>
          <Box>
            <Button to={'/'} variant="rounded" color="primary">
              <FormattedMessage id={`error.button.label`} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Section>
  )
}
